<template>
  <section>
    <div class="columns is-gapless is-marginless is-multiline m1rem">
      <b-field class="column is-12 is-narrow mt1rem inputFieldWrap">
          <b-input 
            type="search"
            :placeholder="label" 
            v-model="inputValue" 
            @focus="showModel(true)" 
            style="max-width: 250px"
            :class="hasChanges ? 'isActive' : ''">
          </b-input>
          <b-icon v-if="show && !hasChanges"
              :icon="'close'"
              size="is-small"
              class="closeSelectNoneBtn"
              @click.native="selectNone">
          </b-icon>
          <b-icon v-if="show && hasChanges"
              :icon="'circle'"
              size="is-small"
              class="closeSelectAllBtn"
              @click.native="selectAll">
          </b-icon>
          <b-icon
              :icon="show ? 'menu-up' : ''"
              size="is-medium"
              class="closeBtn"
              @click.native="close">
          </b-icon>
      </b-field>
      
      <div v-if="show" class="column is-12 is-narrow selectOptionsOuterWrapper">
        <div class="selectOptionsInnerWrapper">
          <div v-for="(tvalue, tkey) in value" v-bind:key="`multiselect_${tkey}`" class="column is-12 is-narrow isOption">
            <b-field v-show="filterOption(tvalue)">
              <b-checkbox 
                v-model="tvalue.value" 
                @input="emitChange"
              >
                {{tvalue.label}}
              </b-checkbox>
            </b-field>
          </div>
        </div>
      </div>        
      
    </div>
  </section>
</template>

<script>

// import { DialogProgrammatic as Dialog } from 'buefy'

export default {
  name: "multiselect",
  
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: Array,
      required: true,
      default: () => []
    },
  },
      
  data() {
    return {
      inputValue: '',
      show: false,
      hasChanges: false,
    }
  },
  
  mounted(){
    this.show = this.isOpen
  },
  
  methods: {
    filterOption(option){
      if (!this.inputValue || this.inputValue.trim() === '') return true
      if (option.label.toLowerCase().indexOf(this.inputValue.trim().toLowerCase()) > -1) return true
      return false
    },
    showModel(show){
      this.show = show
    },
    close(){
      this.inputValue = ''
      this.show = false
    },
    selectNone(){
      for(let item of this.value) {
        item.value = false
      }
      this.emitChange()
    },
    selectAll(){
      for(let item of this.value) {
        item.value = true
      }
      this.emitChange()
    },
    emitChange(){
      let hasChanges = false
      for(let item of this.value) {
        if (item.value !== true) {
          hasChanges = true
        }
      }
      this.hasChanges = hasChanges
      this.$emit('change', true)
    }
  }
};
</script>

<style scoped>
.selectOptionsOuterWrapper{
  position: relative;
  top:0px;
  left:0;
  width: auto;
  height: auto;    
  z-index: 999;
  font-size: 11px;
  /* background-color: red */
  
}
.selectOptionsInnerWrapper{
  float: left;
  position: absolute;
  margin: 0;
  /* margin-top: 5px; */
  padding: 0;
  top:0px;
  left:0;
  width: 250%;
  overflow: visible;
  height: auto;    
  background-color: #FFFFFF;
  border: 1px solid #AEAEAE;
  z-index:999;
}

.inputFieldWrap{
  position: relative;
}

.closeBtn{
  position: absolute;
  float: right;
  left: 170px;
  top: 6px;
  z-index: 999;
  
}
.closeBtn:hover{
  cursor: pointer;
  color: grey;
}

.closeSelectNoneBtn{
  position: absolute;
  float: right;
  left: 150px;
  top: 14px;
  z-index: 999;
  /* border: 1px solid black */
}
.closeSelectNoneBtn:hover{
  cursor: pointer;
  color: grey;
  /* border: 1px solid black */
}

.closeSelectAllBtn{
  position: absolute;
  float: right;
  left: 150px;
  top: 15px;
  z-index: 999;
}
.closeSelectAllBtn:hover{
  cursor: pointer;
  color: grey;
}


.isOption{
  display:inline;
  float:left;
  /* position: relative; */
  padding:3px;
  /* padding-left:12px; */
  margin:0;
  
  width: auto;
  height: auto;
  z-index:999;
  /* background-color: green; */
}
.isActive{
  border: 1px solid rgb(142, 142, 255)
}
  
</style>
