const SuperadminPage = () => import('@/views/superadmin/superadminpage')
const companyoverview = () => import('@/views/superadmin/schedules/companyoverview')
const allUseroverview = () => import('@/views/superadmin/userOverview')
const useroverview = () => import('@/views/superadmin/schedules/useroverview')
const companydetails = () => import('@/views/superadmin/schedules/companyDetails')

const schedulePermissions = () => import('@/views/superadmin/schedules/schedulePermissions')

const vacancydetails = () => import('@/views/superadmin/schedules/vacancyDetails')
const userResponseDetails = () => import('@/views/superadmin/schedules/userResponseDetails')
const companyPerformanceOverview = () => import('@/views/superadmin/schedules/companyPerformanceOverview')
const invoices = () => import('@/views/superadmin/schedules/invoices')
const emailTemplate = () => import('@/views/superadmin/schedules/emailTemplate')
const bigchecker = () => import('@/views/superadmin/schedules/bigchecker')
const importShifts = () => import('@/views/superadmin/schedules/importShifts')
const maximusJson = () => import('@/views/superadmin/schedules/maximusJson')
const maximusRun = () => import('@/views/superadmin/schedules/maximusRun')
const maximusPreview = () => import('@/views/superadmin/schedules/maximusPreview')
const maximusRunServerManager = () => import('@/views/superadmin/schedules/maximusRunServerManager')

const shiftRates = () => import('@/views/superadmin/schedules/shiftRates')

const Database        = () => import('@/views/superadmin/database')
const roles = () => import('@/views/superadmin/roles')
const sms = () => import('@/views/superadmin/sms')
const email = () => import('@/views/superadmin/email')
const servermanage = () => import('@/views/superadmin/servermanage')
const intershiftImport = () => import('@/views/superadmin/intershiftImport')
// import editor                from "@/views/public/editor";
const editor = () => import('@/views/superadmin/editor')




const testpage = () => import('@/views/superadmin/testpage')
// const Streams = () => import('@/views/superadmin/streams')
// const UserStats = () => import('@/views/superadmin/UserStats')
// const Database = () => import('@/views/superadmin/database')


 const Routes_SuperAdmin = [{
                              path: "/superadmin",
                              name: "SuperadminPage",
                              component: SuperadminPage,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/userpage",
                              name: "userpage",
                              component: SuperadminPage,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/users",
                              name: "AllUserOverview",
                              component: allUseroverview,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/editor",
                              name: "editor",
                              component: editor,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/companies",
                              name: "companies",
                              component: companyoverview,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/roles",
                              name: "roles",
                              component: roles,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/sms",
                              name: "sms",
                              component: sms,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/email",
                              name: "email",
                              component: email,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/testpage",
                              name: "testpage",
                              component: testpage,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/company/:companyId/users",
                              name: "useroverview",
                              component: useroverview,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/company/:companyId/intershiftImport",
                              name: "intershiftImport",
                              component: intershiftImport,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/company/:companyId/emailTemplate",
                              name: "emailTemplate",
                              component: emailTemplate,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/company/:companyId",
                              name: "companydetails",
                              component: companydetails,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/company/:companyId/schedulePermissions",
                              name: "schedulePermissions",
                              component: schedulePermissions,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/company/:companyId/vacancy/:vacancyId",
                              name: "vacancyDetails",
                              component: vacancydetails,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/company/:companyId/user/:userId",
                              name: "userResponseDetails",
                              component: userResponseDetails,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/company/:companyId/performance",
                              name: "companyPerformanceOverview",
                              component: companyPerformanceOverview,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/company/:companyId/bigchecker",
                              name: "companyPerformanceObigcheckerverview",
                              component: bigchecker,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/company/:companyId/importShifts",
                              name: "importShifts",
                              component: importShifts,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/Database",
                              name: "Database",
                              component: Database,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/company/:companyId/invoices",
                              name: "invoices",
                              component: invoices,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/company/:companyId/rates/shift/:shiftId",
                              name: "shiftRates",
                              component: shiftRates,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/servermanage",
                              name: "servermanage",
                              component: servermanage,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/company/:companyId/maximus",
                              name: "maximus",
                              component: maximusJson,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/company/:companyId/maximus/json/:jsonPath/run/:runPath",
                              name: "maximusRun",
                              component: maximusRun,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/company/:companyId/maximus/json/:jsonPath/run/:runPath/preview/:file",
                              name: "maximusPreview",
                              component: maximusPreview,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            }, {
                              path: "/company/maximus/runServerManager",
                              name: "maximusRunServerManager",
                              component: maximusRunServerManager,
                              // meta: { superadminNav: true },
                              meta: { navBar: true, hideFooter : true  },
                            // }, {
                            //   path: "/UserStats",
                            //   name: "UserStats",
                            //   component: UserStats,
                            //   // meta: { superadminNav: true },
                            //   meta: { navBar: true, hideFooter : true  },
                            // }, {
                            //   path: "/testpage",
                            //   name: "testpage",
                            //   component: TestPage,
                            //   // meta: { superadminNav: true },
                            //   meta: { navBar: true, hideFooter : true  },
                            // }, {
                            //   path: "/Database",
                            //   name: "Database",
                            //   component: Database,
                            //   // meta: { superadminNav: true },
                            //   meta: { navBar: true, hideFooter : true  },
                            }
];

export default Routes_SuperAdmin;