<template>
    <section>
      <div class="columns is-gapless is-marginless is-multiline m1rem">
        <b-field class="column is-12 is-narrow mt1rem inputFieldWrap">
            <b-input 
              type="search"
              :placeholder="label" 
              v-model="inputValue" 
              @focus="showModel(true)" 
              style="max-width: 250px"
              :class="hasChanges ? 'isActive' : ''">
            </b-input>
            <b-icon v-if="show && hasChanges"
              :icon="'close'"
              size="is-small"
              class="clearBtn"
              @click.native="clearBtn">
          </b-icon>
            <b-icon
                :icon="show ? 'menu-up' : ''"
                size="is-medium"
                class="closeBtn"
                @click.native="close">
            </b-icon>
        </b-field>
        
        <div v-if="show" class="column is-12 is-narrow sliderOptionsOuterWrapper">          
          <b-field class="sliderOptionsInnerWrapper">
            <b-slider v-model="numbers" :min="min" :max="max" :step="step" :ticks="ticks" @input="emitChange">
            </b-slider>
          </b-field>
        </div>
        
      </div>
    </section>
</template>

<script>

export default {
    name: "multiSlider",
    
    props: {
      label: {
        type: String,
        required: false,
        default: ''
      },
      isOpen: {
        type: Boolean,
        required: false,
        default: false
      },
      min: {
        type: Number,
        required: false,
        default: 0
      },
      max: {
        type: Number,
        required: false,
        default: 50
      },      
      step: {
        type: Number,
        required: false,
        default: 0.01
      },      
      ticks: {
        type: Boolean,
        required: false,
        default: false
      },      
      value: {
        type: [ Array, Number ],
        required: false,
        default: 50
      }
    },
        
    data() {
      return {
        inputValue: '',
        numbers: [],
        show: false,
        hasChanges: false,
      }
    },
    
    mounted(){
      this.numbers = this.value
      this.showModel(this.isOpen)
    },
    
    methods: {
      showModel(show){
        this.show = show
        this.checkChanges()
      },
      close(){
        this.inputValue = ''
        this.show = false
        this.checkChanges()
      },
      clearBtn(){
        this.numbers = [this.min, this.max]
        this.emitChange()     
      },
      checkChanges(){
        this.hasChanges = this.value[0] !== this.min || this.value[1] !== this.max        
      },
      emitChange(){
        this.checkChanges()
        this.$emit('change', this.numbers)
      }
    }
};
</script>

<style scoped>
  .sliderOptionsOuterWrapper{
    clear: both;
    float:left;
    position: relative;
    top:0px;
    left:0;
    width: 500px;
    height: auto;    
    /* background-color: #FFFFFF; */
    /* background-color: red; */
  }
  .sliderOptionsInnerWrapper{
    float: left;
    clear:both;    
    position: absolute;
    margin: 0;
    padding: 0;
    padding-top: 30px;    
    /* margin-bottom: 10px;     */
    /* padding-bottom: 20px; */
    top:0px;
    left:0px;
    /* width: 205px; */
    width: 300%;
    overflow: visible;
    height: 60px;    
    background-color: #FFFFFF;
    z-index:999;
    /* background-color: green; */
    border: 1px solid #AEAEAE;
  }
  
  .inputFieldWrap{
    position: relative;
  }
  
  .closeBtn{
    position: absolute;
    float: right;
    left: 170px;
    top: 6px;
    z-index: 999;
    
  }
  .closeBtn:hover{
    cursor: pointer;
    color: grey;
  }
  
  .clearBtn{
    position: absolute;
    float: right;
    left: 150px;
    top: 15px;
    z-index: 999;
  }
  .clearBtn:hover{
    cursor: pointer;
    color: grey;
  }
  
  .isOption{
    display:inline;
    float:left;
    /* position: relative; */
    padding:3px;
    /* padding-left:12px; */
    margin:0;
    
    width: auto;
    height: auto;
    z-index:999;
    /* background-color: green; */
  }
  
  .isActive{
    border: 1px solid rgb(142, 142, 255)
  }
    
</style>
